<template>
  <svg xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#a)">
      <path d="m16.172 11-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2h12.172Z" />
    </g>
    <defs>
      <clipPath id="a"><path d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
